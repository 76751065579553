import React, { useCallback, useEffect, useRef, useState } from 'react'
import IPropsIframe from '../../types/IPropsIFrame'
import { Span } from '../Typography'
import styled from './styles'
import api from '../../services/api'
import { endpointsCreator } from '../../services/endpoints'

interface PropsMessage {
	data: { type: string }
}

export default ({ patientId, email, clientId, clientSecret, onLoad, origin, onClose }: IPropsIframe) => {
	const refIframe = useRef<HTMLIFrameElement>(null)
	const [message, setMessage] = useState<PropsMessage>()
	const [isUserLoaded, setIsUserLoaded] = useState(false)
	const handleLoad = () => {
		if (onLoad) onLoad()
		if (refIframe?.current?.contentWindow) {
			refIframe?.current?.contentWindow?.postMessage(
				{
					client_id: clientId,
					client_secret: clientSecret,
					email,
					type: 'LOGAR'
				},
				origin
			)
		}
	}

	const receiveMessage = useCallback((event: any) => setMessage(event), [])

	const loadPatient = useCallback(() => {
		if (refIframe?.current?.contentWindow) {
			refIframe?.current?.contentWindow?.postMessage(
				{
					paciente: patientId,
					type: 'PACIENTE'
				},
				origin
			)
		}
	}, [patientId, origin, refIframe])

	useEffect(() => {
		if (window.addEventListener) {
			window.addEventListener('message', receiveMessage, false)
		} else {
			// @ts-ignore
			refIframe?.current?.attachEvent('onmessage', receiveMessage)
		}
	}, [receiveMessage, refIframe])
	useEffect(() => {
		if (refIframe?.current) refIframe.current.style.height = `${window.innerHeight}px`

		if (!message) {
			return
		}
		switch (message.data.type) {
			case 'EMBARCADO_CARREGADO':
				handleLoad()
				break
			case 'USUARIO_CARREGADO':
				loadPatient()
				break
			case 'PACIENTE_CARREGADO':
				setIsUserLoaded(true)
				updateDoctorMedicalRecordActive()
				break
			case 'ATENDIMENTO_INICIADO':
				break
			case 'ATENDIMENTO_CANCELADO':
				break
			case 'ATENDIMENTO_FINALIZADO':
				onClose(message.data.idAtendimento, patientId)
				break
			default:
				if (process.env.NODE_ENV === 'development') console.log('Mensagem não tratada', message.data.type)
		}
	}, [message, refIframe])

	const updateDoctorMedicalRecordActive = useCallback(async () => {
		const endpoint = endpointsCreator()

		await api.post(
			endpoint.DOCTOR.POST_MEDICAL_RECORD_ACTIVE,
			{
				doctorReference: email,
				patientReference: patientId,
			}
		)
	}, [patientId, email])

	return (
		<>
			<iframe
				{...{
					ref: refIframe,
					src: origin + '/embarcado.html',
					frameBorder: '0',
					width: '100%'
				}}
				style={{ visibility: isUserLoaded ? 'visible' : 'hidden', display: isUserLoaded ? 'block' : 'none' }}
			/>
			<styled.Container style={{ visibility: !isUserLoaded ? 'visible' : 'hidden' }}>
				<Span bold fontSize='20px/20px' padding='50px 0 10px 0'>
					Não existe prontuários para visualizar
				</Span>
				<Span fontSize='16px/20px' padding='0 0 10px 0'>Caso você perdeu o acesso aos prontuários
					ou deseja criar um novo <br /> acesso, entre em contato pelos canais de comunicação abaixo:
				</Span>
				<a href='tel:+551130502000' target='_blank' style={{ textDecoration: 'none' }}><Span>Telefone: (11)
					3050-2000 opção (1)</Span></a>
				<a href='https://api.whatsapp.com/send?phone=551134748011' target='_blank' style={{ textDecoration: 'none' }}>
					<Span bold fontSize='16px/20px' color='neutralXSDarktest' textAlign='center' color='#171E26FF'>
						WhatsApp: (11) 3474-8011 opção (Quero conhecer)</Span></a>
				<a href='mailto:comercial@prontmed.com' target='_blank' style={{ textDecoration: 'none' }}>
					<Span bold fontSize='16px/20px' textAlign='center' color='#171E26FF'>E-mail: comercial@prontmed.com</Span></a>
			</styled.Container>
		</>
	)
}
